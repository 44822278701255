import axios from 'axios'
import _ from "lodash";

export default {
  namespaced: true,
  state() {
    return {
      types: ['ranches', 'professional_hunting', 'taxidermy', 'processing'],
      loading: {
        ranches: false,
        professional_hunting: false,
        taxidermy: false,
        processing: false,
      },
      list: {
        ranches: [],
        professional_hunting: [],
        taxidermy: [],
        processing: [],
      },
      next_url: {
        ranches: null,
        professional_hunting: null,
        taxidermy: null,
        processing: null,
      },
      prev_url: {
        ranches: null,
        professional_hunting: null,
        taxidermy: null,
        processing: null,
      }
    }
  },
  mutations: {
    setList(state, {type, data}) {
      state.list[type] = data
    },
    setLoading(state, {type, value}) {
      state.loading[type] = value
    },
    setNextUrl(state, {type, value}) {
      state.next_url[type] = value
    },
    setPrevUrl(state, {type, value}) {
      state.prev_url[type] = value
    }
  },
  actions: {
    async loadMapData({state, commit, rootGetters}, payload) {
      const type = _.get(payload, 'type', null)
      const state_code = _.get(payload, 'state_code', '')
      const next_prev_url = _.get(payload, 'next_prev_url', null)
      if (type === null || state.loading[type]) return;
      commit('setLoading', {type, value: true})
      commit('setList', {type, data: []})
      const url = next_prev_url ? next_prev_url : rootGetters["Constant/getApiUrl"](`map-data/${type}/${state_code}`)
      const {data} = await axios.get(url)
      commit('setNextUrl', {type, value: _.get(data, 'data.next_page_url', null)})
      commit('setPrevUrl', {type, value: _.get(data, 'data.prev_page_url', null)})
      commit('setList', {type, data: _.get(data, 'data.data', [])})
      commit('setLoading', {type, value: false})
    }
  }
}
