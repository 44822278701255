import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            list: [],
            loading: false,
            is_dating: false,
            nextPage: null
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setList(state, payload) {
            state.list = payload
        },
        setNextPage(state, payload) {
            try {
                state.nextPage = (new URL(payload)).searchParams.get('page')
            } catch (e) {
                state.nextPage = payload
            }
        }
    },
    actions: {
        async loadList({commit, state, rootState, rootGetters}, {
            isDatingTab = false,
            searchText = '',
            loadMore = false
        }) {
            if (state.loading || (loadMore && !state.nextPage)) return;

            commit('setLoading', true)
            let url = new URL(rootGetters["Constant/getApiUrl"](`users`))
            url.searchParams.set('orderBy', 'name')
            url.searchParams.set('sortedBy', 'asc')
            url.searchParams.set('dating_users', (isDatingTab ? 1 : 0).toString())
            if (searchText)
                url.searchParams.set('search', `name:${searchText};email:${searchText}`)

            if (loadMore && state.nextPage)
                url.searchParams.set('page', state.nextPage)

            let {data} = await axios.get(url.toString())
            let list = _.get(data, 'data.data', [])

            if (loadMore && state.nextPage)
                list = [...state.list, ...list]

            commit('setList', list)
            commit('setNextPage', _.get(data, 'data.next_page_url', null))
            commit('setLoading', false)
        },
    }
}
