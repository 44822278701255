import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            products: [],
            product: {},
            my_products: [],
            admin_products: [],
            paginate: {},
            my_paginate: {}
        }
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setMyProducts(state, payload) {
            state.my_products = payload
        },
        setAdminProducts(state, payload) {
            state.admin_products = payload
        },
        setPaginate(state, payload) {
            state.paginate = {
                first_page_url: payload?.first_page_url ?? null,
                last_page_url: payload?.last_page_url ?? null,
                prev_page_url: payload?.prev_page_url ?? null,
                next_page_url: payload?.next_page_url ?? null,
                current_page: payload?.current_page ?? null,

                from: payload?.from ?? null,
                to: payload?.to ?? null,
                last_page: payload?.last_page ?? null,

                per_page: payload?.per_page ?? null,
                total: payload?.total ?? null,

                links: payload?.links ?? []
            }
        },
        setMypaginate(state, payload) {
            state.my_paginate = {
                first_page_url: payload?.first_page_url ?? null,
                last_page_url: payload?.last_page_url ?? null,
                prev_page_url: payload?.prev_page_url ?? null,
                next_page_url: payload?.next_page_url ?? null,
                current_page: payload?.current_page ?? null,

                from: payload?.from ?? null,
                to: payload?.to ?? null,
                last_page: payload?.last_page ?? null,

                per_page: payload?.per_page ?? null,
                total: payload?.total ?? null,

                links: payload?.links ?? []
            }
        }
    },
    getters: {
        getProducts(state) {
            return state.products
        },
        getProduct(state) {
            return state.product
        },
        getMyProducts(state) {
            return state.my_products
        },
        getAdminProducts(state) {
            return state.admin_products
        },
    },
    actions: {
        async loadProducts({rootState, commit}, {query = null, category_id = null, state_id = null, next_url = null}) {
            const url = next_url !== null ? next_url : rootState.Constant.api_url + '/products'
            let {data} = await axios.post(url, {query, category_id, state_id})
            let list = _.get(data, 'data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setProducts', list);
            commit('setPaginate', data?.data)
        },
        async loadProduct({rootState, commit}, id) {
            let res = await axios.get(rootState.Constant.api_url + '/products/show/' + id)
                .then((res) => {
                    commit('setProduct', res.data);
                })
        },
        async loadMyProducts({rootState, commit}, {query = null, category_id = null, state_id = null, next_url = null}) {
            const url = next_url !== null ? next_url : rootState.Constant.api_url + '/my-products'

            let {data} = await axios.post(url, {
                query, category_id, state_id
            })
            let list = _.get(data, 'data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setMyProducts', list);
            commit('setMypaginate', data?.data)
        },
        async markAsSold({rootState, commit}, id) {
            let res = await axios.get(rootState.Constant.api_url + '/products/mark-as-sold/' + id)
            return res;
        },
        async openToSell({rootState, commit}, id) {
            let res = await axios.get(rootState.Constant.api_url + '/products/open-to-sell/' + id)
            return res;
        },
        async loadAdminProducts({rootState, commit}, query = null, category_id = null) {
            let list = await axios.post(rootState.Constant.api_url + '/admin/products/', query)
            list = _.get(list, 'data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setAdminProducts', list);
        },
    }
}
