import {createRouter, createWebHistory} from "vue-router";

import store from "./store/index.js"

const RegisterPage = () => import('./pages/Register.vue')
const LoginPage = () => import('./pages/Login.vue')
const LogoutPage = () => import('./pages/LogoutPage.vue')
const PostAdventure = () => import('./pages/PostAdventure.vue')
const PostAdventureSingle = () => import('./pages/PostAdventureSingle.vue')
const HomePage = () => import('./pages/Home.vue')
const RanchLocator = () => import('./pages/RanchLocator.vue')
const TaxidermyAndProcessing = () => import('./pages/TaxidermyAndProcessing.vue')
const Outfitters = () => import('./pages/Outfitters.vue')
const Chatroom = () => import('./pages/Chatroom.vue')
const ContactUs = () => import('./pages/ContactUs.vue')
const TieLure = () => import('./pages/TieLure.vue')
const Profile = () => import('./pages/profile/Index.vue')
const ProfileEdit = () => import('./pages/profile/Edit.vue')
const UserProfile = () => import('./pages/UserProfile')
const Products = () => import('./pages/Products.vue')
const Product = () => import('./pages/Product.vue')
const Cart = () => import('./pages/Cart.vue')
const Checkout = () => import('./pages/Checkout.vue')
const Success = () => import('./pages/Success.vue')
const Pay = () => import('./pages/Pay.vue')
const Chat = () => import('./pages/Chat.vue')
const Dating = () => import('./pages/Dating.vue')
const Recipe = () => import('./pages/Recipe.vue')
const Equipment = () => import('./pages/Equipment.vue')
const EquipmentReview = () => import('./pages/EquipmentReview.vue')
const ForgotPassword = () => import('./pages/ForgotPassword.vue')
const ResetPassword = () => import('./pages/ResetPassword.vue')
const PageNotFound = () => import('./pages/PageNotFound.vue')
const Privacy = () => import('./pages/Privacy.vue')
const Terms = () => import('./pages/Terms.vue')
const Marketplace = () => import('./pages/Marketplace.vue')
const MarketplaceTodaysPicks = () => import('./components/MarketplaceTodaysPicks.vue')
const MyListing = () => import('./components/MyListing.vue')
const MarketplaceDetail = () => import('./components/MarketplaceDetail.vue')
const CreateListing = () => import('./pages/CreateListing.vue')
const Categories = () => import('./pages/Categories.vue')
const AdminProducts = () => import('./pages/AdminProducts.vue')
const ChatGroup = () => import('./pages/ChatGroup.vue')
const Orders = () => import('./pages/Orders.vue')
const Hiking = () => import('./pages/Hiking.vue')

const UserDashboardMain = () => import('./pages/UserDashboard/index')
const UserDashboard = () => import('./pages/UserDashboard/Dashboard')
const Friends = () => import('./pages/UserDashboard/Friends')
const BlockFriends = () => import('./pages/UserDashboard/BlockFriends')
const Notification = () => import('./pages/UserDashboard/Notification')
const Messages = () => import('./pages/UserDashboard/Messages')
const AlertRecipes = () => import('./pages/UserDashboard/AlertRecipes')
const AlertAdventurePost = () => import('./pages/UserDashboard/AlertAdventurePost')
const ProfileSettings = () => import('./pages/UserDashboard/profileSettings')


const routes = [
  {
    name: 'chat-group',
    path: '/chat-group',
    component: ChatGroup,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'hiking',
    path: '/hiking',
    component: Hiking,
  },
  {
    name: 'orders',
    path: '/orders',
    component: Orders,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'home',
    path: '/',
    component: HomePage,
    // meta: {guest: true, isRoles: [2, 3, 4]}
    // meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'ranch-locator',
    path: '/ranch-locator',
    component: RanchLocator,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'taxidermy-and-processing',
    path: '/taxidermy-and-processing',
    component: TaxidermyAndProcessing,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'outfitters',
    path: '/outfitters',
    component: Outfitters,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'chatroom',
    path: '/chatroom',
    component: Chatroom,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'contact-us',
    path: '/contact-us',
    component: ContactUs,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'how-to',
    path: '/how-to',
    component: TieLure,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'post-adventure-post',
    path: '/post-adventure/:id',
    component: PostAdventureSingle,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'post-adventure',
    path: '/post-adventure',
    component: PostAdventure,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'profile_edit',
    path: '/profile/edit',
    component: ProfileEdit,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'user-profile',
    path: '/user-profile/:id',
    component: UserProfile,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'product',
    path: '/store/:id',
    component: Product,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'products',
    path: '/store',
    component: Products,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'cart',
    path: '/cart',
    component: Cart,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'checkout',
    path: '/checkout',
    component: Checkout,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'success',
    path: '/success/:id',
    component: Success,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'pay',
    path: '/pay',
    component: Pay,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'chat',
    path: '/chat',
    component: Chat,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'recipe',
    path: '/recipe',
    component: Recipe,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'register',
    path: '/register',
    component: RegisterPage,
    meta: {guest: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'login',
    path: '/login',
    component: LoginPage,
    meta: {guest: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {guest: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'reset-password',
    path: '/reset-password/:token',
    component: ResetPassword,
    meta: {guest: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'logout',
    path: '/logout',
    component: LogoutPage,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]}
  },
  {
    name: 'dating',
    path: '/dating',
    component: Dating,
    meta: {requiresAuth: true, isRoles: [4]}
  },
  {
    name: 'equipment',
    path: '/equipment',
    component: Equipment,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'equipment-review',
    path: '/equipment/review/:id',
    component: EquipmentReview,
    meta: {requiresAuth: true, isRoles: [3, 4]}
  },
  {
    name: 'Privacy',
    path: '/privacy',
    component: Privacy,
    meta: {requiresAuth: false}
  },
  {
    name: 'Terms',
    path: '/terms',
    component: Terms,
    meta: {requiresAuth: false}
  },
  {
    name: 'Marketplace',
    path: '/marketplace',
    component: Marketplace,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]},
    children: [
      {
        name: 'TodaysPicks',
        path: '',
        component: MarketplaceTodaysPicks,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'MyListing',
        path: '/my-listing',
        component: MyListing,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'MarketplaceDetail',
        path: 'detail/:id',
        component: MarketplaceDetail,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]},
        props: true
      },
    ]
  },
  {
    name: 'CreateListing',
    path: '/marketplace/create-listing',
    component: CreateListing,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]},
  },
  {
    name: 'categories',
    path: '/categories',
    component: Categories,
    meta: {requiresAuth: true, isRoles: [0]}
  },
  {
    name: 'admin-products',
    path: '/products',
    component: AdminProducts,
    meta: {requiresAuth: true, isRoles: [0]}
  },
  {
    // name: 'user-dashboard',
    path: '/dashboard',
    component: UserDashboardMain,
    meta: {requiresAuth: true, isRoles: [2, 3, 4]},
    children: [
      {
        name: 'user-dashboard',
        path: '',
        // component: UserDashboard,
        component: ProfileSettings,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'current-friends',
        path: 'friends',
        component: Friends,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'block-friends',
        path: 'block-friends',
        component: BlockFriends,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'notifications',
        path: 'notifications',
        component: Notification,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'messages',
        path: 'messages',
        component: Messages,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'alert-recipes',
        path: 'alert-recipes',
        component: AlertRecipes,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'alert-post-adventure',
        path: 'alert-post-adventure',
        component: AlertAdventurePost,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
      {
        name: 'dashboard-profile-settings',
        path: 'dashboard-profile-settings',
        component: ProfileSettings,
        meta: {requiresAuth: true, isRoles: [2, 3, 4]}
      },
    ]
  },
  {path: "/:pathMatch(.*)*", component: PageNotFound}
]

const router = createRouter({
    // history: createWebHistory(process.env.NODE_ENV === 'production' ? '/' : '/hunterssocial/public/'),
    history: createWebHistory(process.env.NODE_ENV === 'production' ? '/' : '/'),
    routes
})

router.beforeEach(async (to, from, next) => {
  removeBackdrop()
  if (to.matched.some(record => record.meta.requiresAuth)) {
    await store.dispatch('Auth/checkAuth')
    if (store.state.Auth.is_authenticated) {
      let roles = []
      to.matched.some(record => {
        roles = record.meta.isRoles
        return record.meta.isRoles
      })
      if (roles && roles.indexOf(store.state.Auth.role_id) < 0) {
        // return next({name: 'home'})
        // alert("You are not allowed to view this page. Please upgrade your account.")
        store.dispatch('Snackbar/showSnackbar', {
          type: 'error',
          text: 'You are not allowed to view this page. Please upgrade your account.',
        });
        // document.write(roles.indexOf(store.state.Auth.role_id));

        // document.write('User Role: ' + roles[roles.indexOf(store.state.Auth.role_id)]);
        return next({name: from.name})
        // return next()
      }
      // else {
      //     document.write('User Role: ' + roles[roles.indexOf(store.state.Auth.role_id)]);
      // }
      return next()
    } else {

      store.dispatch('Snackbar/showSnackbar', {
        type: 'error',
        text: 'Please login in first',
      });

    }
    next('/')

  } else if (to.matched.some(record => record.meta.guest)) {
    if (window.localStorage.getItem('api_token'))
      // return next({name: 'post-adventure'})
      return next({name: 'profile'})
    next()
  } else if (to.matched.some(record => record.meta.requiresCart)) {
    if (store.state.Product.cart?.count > 0) {
      next()
      return
    }
    next('/store')
  } else {
    await store.dispatch('Auth/checkAuth')
    next()
  }
})

export default router

const removeBackdrop = () => {
  const divs = document.querySelectorAll(".modal-backdrop.fade.show")
  if (divs) {
    const body = document.body
    if (body.classList.contains('modal-open')) {
      body.classList.remove('modal-open')
      body.removeAttribute('style');
    }
    divs.forEach(item => {
      item.remove()
    })
  }
}

