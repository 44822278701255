import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            list: [],
            reviews: [],
            reviews_next_url: null,
            selected_item: null,
            loading: false,
            is_deleting: false,
            is_reviews_loading: false
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setList(state, payload) {
            state.list = payload
        },
        setSelectedItem(state, payload) {
            state.selected_item = payload
        },
        setReviews(state, payload) {
            state.reviews = payload
        },
        setReviewNextUrl(state, payload) {
            state.reviews_next_url = payload
        },
        setIsDeleting(state, payload) {
            state.is_deleting = payload
        },
        setIsReviewsLoading(state, payload) {
            state.is_reviews_loading = payload
        }
    },
    actions: {
        async loadList({rootState, commit}) {
            let list = await axios.get(rootState.Constant.api_url + '/equipments?orderBy=created_at&sortedBy=desc')
            commit('setList', _.get(list, 'data.data', []))
        },
        async loadItem({rootGetters, commit}, id) {
            commit('setLoading', true)
            let item = await axios.get(rootGetters["Constant/getApiUrl"](`equipments/${id}`))
            commit('setSelectedItem', _.get(item, 'data.data', null))
            commit('setLoading', false)
        },
        async loadItemReviews({state, rootGetters, commit}, {id, url}) {
            commit('setIsReviewsLoading', true)
            url = url ? url : rootGetters["Constant/getApiUrl"](`equipments/reviews/${id}`)
            let list = await axios.get(url)
            commit('setReviewNextUrl', _.get(list, 'data.data.next_page_url', null))
            const listData = _.get(list, 'data.data.data', [])
            commit('setReviews', _.concat(state.reviews, listData))
            commit('setIsReviewsLoading', false)
        },
        // submit({dispatch, commit, rootGetters, rootState}, {content, rating, item_id}) {
        submit({dispatch, commit, rootGetters, rootState}, {item_id, payload}) {
            if (rootState.FormUtils.isFormLoading) return false
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('FormUtils/setIsFormLoading', true, {root: true})
                commit('FormUtils/setAllFormErrors', {
                    content: "",
                    rating: "",
                    reviewable_id: ""
                }, {root: true})
                axios.post(rootGetters["Constant/getApiUrl"](`equipments/reviews/${item_id}`), payload).then((res) => {
                    commit('FormUtils/setIsFormLoading', false, {root: true})
                    commit('setReviews', [])
                    dispatch('loadItemReviews', {id: item_id, url: null})
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Review posted successfully."
                    }, {root: true})
                    resolve(res)
                }).catch((err) => {
                    commit('FormUtils/setIsFormLoading', false, {root: true})
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    reject(err)
                })
            })
        },
        deleteReview({dispatch, commit, rootGetters, state, rootState}, {review_id, item_id}) {
            if (state.is_deleting) return false
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('setIsDeleting', true)
                axios.delete(rootGetters["Constant/getApiUrl"](`equipments/reviews/${review_id}`)).then((res) => {
                    commit('setIsDeleting', false)
                    commit('setReviews', [])
                    dispatch('loadItemReviews', {id: item_id, url: null})
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Review deleted successfully."
                    }, {root: true})
                    resolve(res)
                }).catch((err) => {
                    commit('setIsDeleting', false)
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: rootState.FormUtils.errMsg
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
