
import axios from "axios";

const state = {
    orderProducts: [],
};

const mutations = {
    SET_ORDER_PRODUCTS(state, products) {
        state.orderProducts = products;
    },
};

const actions = {
    fetchOrderProducts({ commit }, products) {
        commit('SET_ORDER_PRODUCTS', products);
    },
};

const getters = {
    getOrderProducts: (state) => state.orderProducts,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
