import axios from "axios";
import _ from "lodash";

const state = {
    orders: [],
    selectedOrder: null,
};

const mutations = {
    SET_ORDERS(state, orders) {
        state.orders = orders;
    },
    SET_SELECTED_ORDER(state, order) {
        state.selectedOrder = order;
    },
};

const actions = {
    async loadOrders({rootState, commit}) {
        let list = await axios.get(rootState.Constant.api_url + '/orders')
        commit('SET_ORDERS', _.get(list, 'data.data', []))
    },
    setOrders({ commit }, orders) {
        commit("SET_ORDERS", orders);
    },
    setSelectedOrder({ commit }, order) {
        commit("SET_SELECTED_ORDER", order);
    },
};

const getters = {
    // Define getters if needed
    getOrders: (state) => state.orders,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
