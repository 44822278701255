import _ from 'lodash'
import Echo from "laravel-echo";
import axios from "axios";
import store from './index'

const pusher = require('pusher-js')
const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post(store.state.Constant.api_url + '/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then(response => {
                    callback(false, response.data);
                })
                .catch(error => {
                    callback(true, error);
                });
            }
        };
    },
})

export default {
    namespaced: true,
    state() {
        return {
            echo,
            channels: [],
            events: []
        }
    },
    getters: {
        isChannelExist(state) {
            return (channel_name) => {
                return typeof _.find(state.channels, {name: channel_name}) !== 'undefined'
            }
        }
    },
    mutations: {
        setChannel(state, payload) {
            const channelObj = _.find(state.channels, {name: payload})
            if (!channelObj) {
                state.channels.push({
                    name: payload,
                    channel: state.echo.private(payload)
                })
            } else {
                channelObj.channel.subscribe()
            }
        },
        addEvents(state, payload) {
            if (!_.find(state.events, {channel: payload.channel, event: payload.event}))
                state.events.push({
                    channel: payload.channel,
                    event: payload.event
                })
        },
        removeEvent(state, {channel, event}) {
            if (_.find(state.events, {channel, event}))
                _.remove(state.events, {channel, event})
        }
    },
    actions: {
        listen({state, commit}, payload) {
            const channelObj = _.find(state.channels, {name: payload.channel})
            if (channelObj) {
                channelObj.channel.listen(payload.listen, payload.cb)
                commit('addEvents', {
                    channel: payload.channel,
                    event: payload.listen
                })
            }
        },
        removeListener({state, commit}, payload) {
            const channelObj = _.find(state.channels, {name: payload.channel})
            if (channelObj) {
                for (const item of payload.listeners) {
                    channelObj.channel.stopListening(item)
                    commit('removeEvent', {channel: payload.channel, event: item})
                }
            }
        },
        unsubscribeChannel({state}, {name}) {
            const channelObj = _.find(state.channels, {name})
            if (channelObj)
                channelObj.channel.unsubscribe()
        },
        userStatusOnline({state, dispatch, commit, rootState}) {
            state.echo.join("user-online")
                .joining((user) => {
                    let userProfile = _.find([rootState.UserProfile.user_profile], {id: user?.id})
                    if (userProfile) {
                        userProfile['is_user_logged_in'] = 1
                        commit('UserProfile/setUserProfile', userProfile, {root: true})
                    }
                    dispatch('userOnlineReq', user?.id)
                })
                .leaving((user) => {
                    let userProfile = _.find([rootState.UserProfile.user_profile], {id: user?.id})
                    if (userProfile) {
                        userProfile['is_user_logged_in'] = 0
                        commit('UserProfile/setUserProfile', userProfile, {root: true})
                    }
                    dispatch('userOfflineReq', user?.id)
                });
        },
        userStatusChannelUnsubscribe({state}) {
            state.echo.leaveChannel("presence-user-online")
        },
        userOnlineReq({rootGetters}, user_id) {
            axios.put(rootGetters["Constant/getApiUrl"](`user/${user_id}/status-change/online`));
        },
        userOfflineReq({rootGetters}, user_id) {
            axios.put(rootGetters["Constant/getApiUrl"](`user/${user_id}/status-change/offline`));
        },
        adminChatSubscribe({state}, payload) {
            state.echo.private(payload.channel).listen(payload.listen, payload.cb);
        },
    }
}
