import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
  namespaced: true,
  state() {
    return {
      unread_count: 0,
    }
  },
  mutations: {

    setCount(state, payload) {
      state.unread_count = payload
    },

  },
  getters: {},
  actions: {
    async unReadCount({rootState, commit}) {
      let unreadCount = await axios.get(rootState.Constant.api_url + '/users/notifications/unread-count')
      commit('setCount', unreadCount.data.data.count);
    },
    async unReadNotifications({rootState, commit}, data) {
      let res = await axios.post(rootState.Constant.api_url + '/users/notifications/unread-notifications', data)
      // console.log("res" , res);
      commit('setCount', res.data.data.count);
    }
  }
}
