import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            cart: {
                count: 0,
                total: 0,
                data: []
            },
            products: [],
            product: {},
            reviews: []
        }
    },
    mutations: {
        setProducts(state, payload) {
            state.products = payload
        },
        setProduct(state, payload) {
            state.product = payload
        },
        setReviews(state, payload) {
            state.reviews = payload
        },
        setCart(state, payload) {
            state.cart.count = payload.quantity
            state.cart.total = payload.amount
            state.cart.data = payload.data
        }
    },
    getters: {

    },
    actions: {
        async loadProducts({rootState, commit}) {
            let list = await axios.get(rootState.Constant.api_url + '/products')
            list = _.get(list, 'data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setProducts', list)
        },
        async singleProduct({rootState, commit, dispatch}, product_id) {
            let item = await axios.get( rootState.Constant.api_url + '/products/' + product_id )
            item.data.data['created_at_now'] = moment(item['created_at']).fromNow()
            commit('setProduct', item?.data?.data)
        },
        postReview({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/reviews', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        updateReview({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/reviews/' + payload.id, payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        async loadReviews({rootState, commit, dispatch}, product_id) {
            let list = await axios.get(rootState.Constant.api_url + '/reviews/' + product_id)
            list = _.get(list, 'data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setReviews', list)
        },
        async loadCart({rootState, commit, dispatch}) {
            let list = await axios.get( rootState.Constant.api_url + '/cart' )
            _.get(list, 'data.data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setCart', list?.data?.data)
        },
        addToCart({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/add-to-cart', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        increaseQtyCartProduct({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/cart/increase', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        decreaseQtyCartProduct({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/cart/decrease', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        removeCartProduct({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/cart/remove', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        checkoutProcess({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/checkout', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(res?.data?.data)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        },
        orderSuccess({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/order/success', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(res?.data)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
