import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            modalOpen: false,
            after_success: null,
            form_data: {
                type: null,
                id: null
            },
            submitting: false
        }
    },
    mutations: {
        setModalOpen(state, payload) {
            state.modalOpen = payload
        },
        setFormData(state, {type, id}) {
            state.form_data.type = type
            state.form_data.id = id
        },
        setSubmitting(state, payload) {
            state.submitting = payload
        },
        setAfterSuccess(state, payload) {
            state.after_success = payload
        }
    },
    getters: {
        isModalOpen(state) {
            return state.modalOpen
        },
    },
    actions: {
        modalShow({commit}, form) {
            commit('setFormData', form)
            commit('setModalOpen', true)
        },
        modalHide({commit}) {
            commit('setModalOpen', false)
            commit('setFormData', {type: null, id: null})
            commit('setAfterSuccess', null)
        },
        modalAfterSuccessResponse({state}) {
            if (_.isFunction(state.after_success))
                state.after_success()
        },

        async submit({commit, state, rootState, dispatch, rootGetters}, payload) {
            if (state.submitting) return

            commit('setSubmitting', true)
            await axios.post(`${rootState.Constant.api_url}/reported`, payload)
                .then(res => {
                    dispatch('modalAfterSuccessResponse')
                    dispatch('modalHide')
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res.data.message
                    }, {root: true})
                }).catch(err => {
                    console.log("error", err)
                    dispatch('FormUtils/showResponseErrorInSBar', err.response, {root: true})
                }).finally(() => {
                    commit('setSubmitting', false)
                })
        }
    }
}
