import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
        }
    },
    mutations: {

    },
    getters: {

    },
    actions: {
        register({commit, rootState, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/users/dating', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    // dispatch('Snackbar/showSnackbar', {
                    //     type: "error",
                    //     text: err?.response?.data?.message
                    // }, {root: true})
                    reject(err?.response?.data)
                })
            })
        }
    }
}


