import axios from "axios";

export default {
    namespaced: true,
    state() {
        return {
            review: null
        }
    },
    mutations: {
        setReview(state, payload) {
            state.review = payload
        },
    },
    actions: {
        deleteReview({rootState, commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                axios.delete(rootState.Constant.api_url + '/reviews/' + id).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
