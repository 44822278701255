import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            list_collapsed: true,
            active_tab: 'friend', // friend, people, group
            dating_tab_active: false,
            friendList: [],
            peopleList: [],
            datingList: [],
            groupsList: [],
            groupsChatList: [],
            adminChatMessageList: [],
            group_list_loading: false,
            join_group_requesting: false,
            leaving_group: false,
            friendListInit: false,
            peopleListInit: false,
            isPeopleSearch: false,
            searching: false,
            datingSearching: false,
            submitting: false,
            adminChatMessageLoading: false,
            notifyData: [],
            groupNotify: {},
            groupNotifyData: [],
            adminChatNotifyData: [],
            paginate: {
                first_page_url: null,
                last_page_url: null,
                prev_page_url: null,
                next_page_url: null,
                last_page: null,
                total: null,
            }
        }
    },
    mutations: {
        setListCollapsed(state, payload) {
            state.list_collapsed = payload
        },
        setActiveTab(state, payload) {
            const tabs = ['friend', 'people', 'group', 'admin'];
            state.active_tab = _.get(tabs, _.indexOf(tabs, payload), 'friend')
        },
        setGroupListLoading(state, payload) {
            state.group_list_loading = payload
        },
        setLeavingGroup(state, payload) {
            state.leaving_group = payload
        },
        setDatingTabActive(state, payload) {
            state.dating_tab_active = payload
        },
        setSearching(state, payload) {
            state.searching = payload
        },
        setSubmitting(state, payload) {
            state.submitting = payload
        },
        resetInit(state) {
            state.peopleListInit = false
            state.friendListInit = false
        },
        setGroupsList(state, payload) {
            state.groupsList = payload
        },
        setGroupsChatList(state, payload) {
            state.groupsChatList = payload
        },

        setList(state, {data, isPeopleSearch}) {
            if (isPeopleSearch) {
                state.peopleList = data
                state.peopleListInit = true
            } else {
                _.forEach(state.notifyData, (val) => {
                    if (_.find(data, {id: val.id}))
                        _.set(data, `[${_.findIndex(data, {id: val.id})}].notify_created_at`, val.notify_created_at)
                })

                state.friendList = data
                state.friendListInit = true
            }
        },
        setDatingList(state, payload) {
            state.datingList = payload
        },
        setNotifyData(state, payload) {
            let list = state.notifyData
            if (!_.find(list, {id: payload.userId}))
                list.push({
                    id: payload.userId,
                    notify_created_at: payload.created_at
                })
            else _.set(list, `[${_.findIndex(list, {id: payload.userId})}.notify_created_at]`, payload.created_at)
            state.notifyData = list
            _.forEach(state.notifyData, (val) => {
                if (_.find(state.friendList, {id: val.id}))
                    _.set(state.friendList, `[${_.findIndex(state.friendList, {id: val.id})}].notify_created_at`, val.notify_created_at)
            })
        },
        setGroupNotify(state, payload) {
            state.groupsChatList = state.groupsList

            let list = state.groupNotifyData
            if (!_.find(list, {id: payload.id}))
                list.push({
                    id: payload.id,
                    notify_created_at: payload.created_at
                })
            else _.set(list, `[${_.findIndex(list, {id: payload.id})}.notify_created_at]`, payload.created_at)
            state.groupNotifyData = list
            _.forEach(state.groupNotifyData, (val) => {
                if (_.find(state.groupsChatList, {channel_id: val.id}))
                    _.set(state.groupsChatList, `[${_.findIndex(state.groupsChatList, {channel_id: val.id})}].notify_created_at`, val.notify_created_at)
            })
        },
        // setGroupNotifyData(state, payload) {
        //     let list = state.groupNotifyData
        //     if (!_.find(list, {id: payload.id}))
        //         list.push({
        //             id: payload.id,
        //             notify_created_at: payload.created_at
        //         })
        //     else _.set(list, `[${_.findIndex(list, {id: payload.id})}.notify_created_at]`, payload.created_at)
        //     state.groupNotifyData = list
        //     _.forEach(state.groupNotifyData, (val) => {
        //         if (_.find(state.groupsList, {channel_id: val.id}))
        //             _.set(state.groupsList, `[${_.findIndex(state.groupsList, {channel_id: val.id})}].notify_created_at`, val.notify_created_at)
        //     })
        // },
        removeNotifyItem(state, id) {
            _.remove(state.notifyData, {id})
            _.set(state.friendList, `[${_.findIndex(state.friendList, {id})}].notify_created_at`, null)
        },
        setIsPeopleSearch(state, payload) {
            state.isPeopleSearch = payload
        },
        setDatingSearching(state, payload) {
            state.datingSearching = payload
        },
        setJoinGroupRequesting(state, payload) {
            state.join_group_requesting = payload
        },
        setAdminChatMessageLoading(state, payload) {
            state.adminChatMessageLoading = payload
        },
        setAdminChatMessageList(state, payload) {
            state.adminChatMessageList = payload
        },
        setAdminChatNotifyData(state, payload) {
            state.adminChatNotifyData = payload
        },
        setPaginate(state, payload) {
            state.paginate.first_page_url = payload?.first_page_url;
            state.paginate.last_page_url = payload?.last_page_url;
            state.paginate.prev_page_url = payload?.prev_page_url;
            state.paginate.next_page_url = payload?.next_page_url;
            state.paginate.last_page = payload?.last_page;
            state.paginate.total = payload?.total;
        }
    },
    getters: {
        list(state) {
            // return state.isPeopleSearch ? state.peopleList : _.orderBy(state.friendList, 'notify_created_at', 'acs')
            return state.isPeopleSearch ? state.peopleList : state.friendList
        },
        formatDob() {
            return (time) => {
                return time ? moment(time).fromNow() : ''
            }
        },
        paginate(state) {
            return state.paginate;
        },
        adminChatMessageList(state) {
            return state.adminChatMessageList;
        },
        groupsList(state) {
            return state.groupsList;
        }
    },
    actions: {
        async search({ commit, state, rootState, rootGetters }, payload) {
            if (state.searching) return;
            const isDatingTab = _.get(payload, 'isDatingTab', false)
            const searchText = _.get(payload, 'searchText', null)
            const isPeopleSearch = _.get(payload, 'isPeopleSearch', false)
            const nextPageUrl = _.get(payload, 'next_page_url', false)
            const channelId = _.get(payload, 'channel_id', null)

            commit('setDatingTabActive', isDatingTab)
            commit('setIsPeopleSearch', isPeopleSearch)
            commit('setSearching', true)
            let params = `orderBy=created_at&sortedBy=desc&dating_users=${isDatingTab ? 1 : 0}` + (searchText ? `&search=name:${searchText};email:${searchText}` : '');
            let setListPayload = {
                isPeopleSearch,
                data: null
            }
            if (isPeopleSearch) {
                let { data } = await axios.get(rootGetters["Constant/getApiUrl"](`users?${params}`))
                setListPayload['data'] = _.get(data, 'data.data', [])
            } else {
                let friendListApiUrl = rootGetters["Constant/getApiUrl"](`users/friends?${params}`);

                if(nextPageUrl) friendListApiUrl = nextPageUrl + '&' + params;
                if(channelId) friendListApiUrl += `&channel_id=${channelId}`;

                let { data } = await axios.get(friendListApiUrl);

                setListPayload['data'] = _.get(data.data, 'data', [])
                commit('setPaginate', _.get(data, 'data', []))
            }
            // setListPayload['data'] = _.sortBy(setListPayload['data'], 'name'); // Sort the array by 'name' property
            commit('setList', setListPayload)
            commit('setSearching', false)
        },
        async datingPeople({commit, state, rootState}, payload) {
            if (state.datingSearching) return;
            commit('setDatingSearching', true)
            const query = _.get(payload, 'query', null)
            const ages = _.get(payload, 'ages', null)
            const genders = _.get(payload, 'genders', null)
            const interests = _.get(payload, 'interests', null)
            const ethnicities = _.get(payload, 'ethnicities', null)
            const us_state = _.get(payload, 'state', null)
            const city = _.get(payload, 'city', null)

            let params = `?orderBy=name`;
            params += query ? `&search=name:${query};email:${query}` : ''
            params += ages ? `&ages=${ages}` : ''
            params += genders ? `&genders=${genders}` : ''
            params += interests ? `&interests=${interests}` : ''
            params += ethnicities ? `&ethnicities=${ethnicities}` : ''
            params += us_state ? `&state=${us_state}` : ''
            params += city ? `&city=${city}` : ''

            try {
                let {data} = await axios.get(`${rootState.Constant.api_url}/users/dating${params}`)
                commit('setDatingList', data?.data?.data ? data?.data?.data : [])
            } catch (e) {
            }

            commit('setDatingSearching', false)
        },
        async addFriend({state, commit, dispatch, rootState, rootGetters}, userId) {
            await dispatch('friendListCURequest', {
                method: 'post',
                path: 'add-friend',
                successText: 'Sent request successfully.',
                userId
            })
            await dispatch('datingPeople')
        },
        async acceptFriendRequest({state, commit, dispatch, rootState, rootGetters}, userId) {
            await dispatch('friendListCURequest', {
                method: 'patch',
                path: 'accept-friend-request',
                successText: 'Accept request successfully.',
                userId
            })
        },
        async ignoreFriendRequest({state, commit, dispatch, rootState, rootGetters}, userId) {
            await dispatch('friendListCURequest', {
                method: 'delete',
                path: 'ignore-friend-request',
                successText: 'Ignore request successfully.',
                userId
            })
        },
        async cancelFriendRequest({state, commit, dispatch, rootState, rootGetters}, userId) {
            await dispatch('friendListCURequest', {
                method: 'delete',
                path: 'cancel-friend-request',
                successText: 'Cancel request successfully.',
                userId
            })
        },
        async unFriendRequest({dispatch}, userId) {
            dispatch('Chat/resetMessagesAndCommits', null, {root: true})
            await dispatch('friendListCURequest', {
                method: 'delete',
                path: 'unfriend-request',
                successText: 'Unfriend successfully.',
                userId
            })
        },
        async blockFriendRequest({dispatch}, userId) {
            dispatch('Chat/resetMessagesAndCommits', null, {root: true})
            await dispatch('friendListCURequest', {
                method: 'patch',
                path: 'block-friend-request',
                successText: 'Blocked friend successfully.',
                userId
            })
        },
        async unblockFriendRequest({dispatch}, userId) {
            await dispatch('friendListCURequest', {
                method: 'patch',
                path: 'unblock-friend-request',
                successText: 'UnBlocked friend successfully.',
                userId
            })
        },
        async friendListCURequest({state, commit, dispatch, rootState, rootGetters}, {
            method,
            path,
            successText,
            userId
        }) {
            if (state.submitting) return;
            return new Promise((resolve, reject) => {
                commit('setSubmitting', true)
                axios({
                    method,
                    url: `${rootState.Constant.api_url}/users/${path}/${userId}`
                }).then(res => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: successText
                    }, {root: true})
                    dispatch('search', {isDatingTab: state.dating_tab_active, isPeopleSearch: state.isPeopleSearch})
                }).catch(err => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: rootGetters["FormUtils/getResponseError"](err, 'userId[0]')
                    }, {root: true})
                }).finally(() => {
                    commit('resetInit')
                    commit('setSubmitting', false)
                    resolve()
                })
            })
        },
        async allGroups({state, commit, rootGetters}, payload) {
            console.log('state.group_list_loading', state.group_list_loading)
            const channelId = _.get(payload, 'channel_id', null);
            const nextPageUrl = _.get(payload, 'next_page_url', false)
            let url = rootGetters["Constant/getApiUrl"]('users/groups');

            if(nextPageUrl) url = nextPageUrl;

            if(nextPageUrl && channelId) url += `&channel_id=${channelId}`;
            else if(channelId) url += `?channel_id=${channelId}`;

            if (state.group_list_loading) return
            commit('setGroupListLoading', true)
            const {data} = await axios.get(url)
            commit('setPaginate', _.get(data, 'data', []))
            commit('setGroupsList', _.get(data, 'data.data', []))
            commit('setGroupListLoading', false)
        },
        async joinGroup({state, commit, dispatch, rootGetters}, payload) {
            if (state.join_group_requesting) return;
            commit('setJoinGroupRequesting', true)
            await axios.post(rootGetters["Constant/getApiUrl"]('users/groups/join'), {channel_id: payload}).then(res => {
                dispatch('Snackbar/showSnackbar', {
                    type: "success",
                    text: rootGetters['FormUtils/getDefault'](res.data, 'message', "Successfully added!")
                }, {root: true})
                dispatch('allGroups')
            }).catch(err => {
                dispatch('Snackbar/showSnackbar', {
                    type: "error",
                    text: rootGetters["FormUtils/getResponseError"](err, 'user_id[0]')
                }, {root: true})
            })
            commit('setJoinGroupRequesting', false)
        },
        async leaveGroup({state, commit, dispatch, rootGetters}, payload) {
            if (state.leaving_group) return;
            commit('setLeavingGroup', true)
            await axios.post(rootGetters["Constant/getApiUrl"]('users/groups/leave'), {channel_id: payload}).then(res => {
                dispatch('Snackbar/showSnackbar', {
                    type: "success",
                    text: rootGetters['FormUtils/getDefault'](res.data, 'message', "Successfully leave!")
                }, {root: true})
                dispatch('allGroups')
            }).catch(err => {
                dispatch('Snackbar/showSnackbar', {
                    type: "error",
                    text: rootGetters["FormUtils/getResponseError"](err)
                }, {root: true})
            })
            commit('setLeavingGroup', false)
        },
        async adminChatMessageList({commit, rootGetters}, payload) {
            commit('setAdminChatMessageLoading', true)

            const nextPageUrl = _.get(payload, 'next_page_url', false)
            let url = rootGetters["Constant/getApiUrl"](`admin-chat-message`);

            if(nextPageUrl) url = nextPageUrl;

            let { data } = await axios.get(url);

            commit('setPaginate', _.get(data, 'data', []))
            commit('setAdminChatMessageList', await _.get(data, 'data.data', []));

            commit('setAdminChatMessageLoading', false)
        }
    }
}
