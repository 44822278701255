import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            list: [],
            next_page_url: null,
            loading: false
        }
    },
    mutations: {
        setList(state, payload) {
            state.list = payload
        },
        setNextPageUrl(state, payload) {
            state.next_page_url = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        }
    },
    actions: {
        async loadList({state, commit, rootGetters}, next_url) {
            if (state.loading) return;
            commit('setLoading', true)
            let url = next_url ? next_url : rootGetters["Constant/getApiUrl"]('groups')
            const {data} = await axios.get(url)
            commit('setNextPageUrl', _.get(data, 'data.next_page_url', null))
            let list = _.get(data, 'data.data', [])
            if (next_url)
                list = _.concat(state.list, list)
            commit('setList', list)
            commit('setLoading', false)
        }
    }
}
