import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            recipes: [],
            paginate: {}
        }
    },
    mutations: {
        setRecipes(state, payload) {
            state.recipes = payload
        },
        setPaginate(state, payload) {
            state.paginate = {
                first_page_url: payload?.first_page_url ?? null,
                last_page_url: payload?.last_page_url ?? null,
                prev_page_url: payload?.prev_page_url ?? null,
                next_page_url: payload?.next_page_url ?? null,
                current_page: payload?.current_page ?? null,

                from: payload?.from ?? null,
                to: payload?.to ?? null,
                last_page: payload?.last_page ?? null,

                per_page: payload?.per_page ?? null,
                total: payload?.total ?? null,

                links: payload?.links ?? []
            }
        }
    },
    getters: {},
    actions: {
        async loadRecipes({rootState, commit}, next_url = null) {
            const url = next_url !== null ? next_url : rootState.Constant.api_url + '/recipes'
            let response = await axios.get(url)
            let list = _.get(response, 'data.data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })

            commit('setRecipes', list)
            commit('setPaginate', response?.data?.data)
        },
        addRecipe({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/recipes', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err?.response?.data)
                })
            })
        },
        deleteRecipe({rootState, commit, dispatch, rootGetters}, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(rootGetters['Constant/getApiUrl']('recipes/' + payload)).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err?.response?.data?.message
                    }, {root: true})
                    reject(err?.response?.data)
                })
            })
        },
        submitReview({dispatch, commit, rootGetters, rootState}, {rating, item_id}) {
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('FormUtils/setAllFormErrors', {
                    rating: "",
                    reviewable_id: "",
                }, {root: true})
                axios.post(rootGetters["Constant/getApiUrl"](`recipes/${item_id}/reviews`), {
                    rating
                }).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Review posted successfully."
                    }, {root: true})
                    resolve(res)
                }).catch((err) => {
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    dispatch('FormUtils/showErrorsInSnackbar', null, {root: true})
                    reject(err)
                })
            })
        },
    },
}
