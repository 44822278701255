import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            form: {},
            formErrors: {},
            errMsg: '',
            successMsg: '',
            isFormLoading: false
        }
    },
    getters: {
        getResponseError() {
            return function (errorObj, key = "") {
                let error = _.get(errorObj, 'response.data.message', "Server error!")
                if (key !== "")
                    error = _.get(errorObj, 'response.data.errors.' + key, "Server error!")
                return error
            }
        },
        getDefault: () => {
            return (payload, path = '', default_val = '') => {
                return _.get(payload, path, default_val)
            }
        }
    },
    mutations: {
        setIsFormLoading(state, payload) {
            state.isFormLoading = payload
        },
        setSuccessMsg(state, payload) {
            state.successMsg = payload
        },
        setAllFormErrors(state, payload) {
            state.formErrors = payload
        },
        setFormError(state, payload) {
            if (state.formErrors.hasOwnProperty(payload.key))
                state.formErrors[payload.key] = payload.message
        },
        setForm(state, payload) {
            if (state.form.hasOwnProperty(payload.key))
                state.form[payload.key] = payload.value
        },
        setErrMsg(state, payload) {
            state.errMsg = payload
        }
    },
    actions: {
        showResponseSuccessMsg({commit}, response) {
            commit('setSuccessMsg', _.get(response, 'message', ''))
        },
        showResponseErrors({commit, state}, response) {
            if (response.hasOwnProperty('errors'))
                for (let key in _.get(response, 'errors', {})) {
                    commit('setFormError', {key, message: _.get(response.errors, `[${key}][0]`, 'Invalid input.')})
                }
            else if (response.hasOwnProperty('message'))
                commit('setErrMsg', response.message)
            else
                commit('setErrMsg', "Server Error!")
        },
        resetErrors({commit, state}) {
            commit('setErrMsg', '')
            for (let key in state.formErrors) {
                commit('setFormError', {key, message: ''})
            }
        },
        resetForm({commit, state}) {
            for (let key in state.form) {
                commit('setForm', {key, value: ''})
            }
        },
        showErrorsInSnackbar({state, dispatch}) {
            const errors = state.formErrors
            let isErrorShow = false
            for (const formKey in errors) {
                if (errors[formKey] !== '') {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: errors[formKey]
                    }, {root: true})
                    isErrorShow = true
                    break
                }
            }
            if (!isErrorShow && state.errMsg !== '') {
                dispatch('Snackbar/showSnackbar', {
                    type: "error",
                    text: state.errMsg
                }, {root: true})
            }
        },
        showResponseErrorInSBar({dispatch}, response) {
            const errors = _.get(response, `data.errors`, [])
            const defError = _.get(response, `data.message`, "Server Error!")
            let isErrorShow = false
            for (const formKey in errors) {
                if (Array.isArray(errors[formKey])) {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: errors[formKey][0]
                    }, {root: true})
                    isErrorShow = true
                    break
                }
            }
            if (!isErrorShow) {
                dispatch('Snackbar/showSnackbar', {
                    type: "error",
                    text: defError
                }, {root: true})
            }
        }
    }
}
