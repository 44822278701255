import axios from "axios";

export default {
    namespaced: true,
    state() {
        return {
            loaded: false,
            email: null,
            phone: null,
            location: null,
            map_link: null,
            copyrights: null,
            facebook: null,
            instagram: null,
            twitter: null,
            pinterest: null,
            lifetime_users_limit: null,
        }
    },
    mutations: {
        setLoaded(state, payload) {
            state.loaded = payload
        },
        setStates(state, payload) {
            state.email = payload?.email ?? null
            state.phone = payload?.phone ?? null
            state.location = payload?.location ?? null
            state.map_link = payload?.map_link ?? null
            state.copyrights = payload?.copyrights ?? null
            state.facebook = payload?.facebook ?? null
            state.instagram = payload?.instagram ?? null
            state.twitter = payload?.twitter ?? null
            state.pinterest = payload?.pinterest ?? null
            state.lifetime_users_limit = payload?.lifetime_users_limit ?? null
        }
    },
    actions: {
        loadSettings({state, commit, rootState}) {
            if (state.loaded) return
            axios.get(rootState.Constant.api_url + '/settings').then(res => {
                commit('setStates', res.data?.data ?? {})
            }).finally(() => {
                commit('setLoaded', true)
            })
        }
    }
}
