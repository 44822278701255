import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            is_authenticated: false,
            user: null,
            token: null,
            addresses: null,
            role_id: null
        }
    },
    getters: {
        isDatingProfile(state) {
            return _.get(state.user, 'profile_detail.is_dating_profile', 0)
        }
    },
    mutations: {
        setIsAuthenticated(state, payload) {
            state.is_authenticated = payload
        },
        setToken(state, payload) {
            state.token = payload
        },
        setUser(state, payload) {
            state.user = payload
        },
        setAddresses(state, payload) {
            state.addresses = payload
        },
        setRoleId(state, payload) {
            state.role_id = payload
        }
    },
    actions: {
        async checkAuth({rootState, commit, dispatch, state}) {
            const token = localStorage.getItem('api_token')
            if (!token) {
                dispatch('authReset')
                return;
            }
            await axios.get(rootState.Constant.api_url + '/user', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }).then((res) => {
                axios.interceptors.request.use(
                    config => {
                        if (state.is_authenticated)
                            config.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
                        else
                            config.headers.common['Authorization'] = null;
                        return config;
                    },
                    error => Promise.reject(error),
                );
                commit('setIsAuthenticated', true)
                commit('setToken', token)
                commit('setUser', _.get(res, 'data.data.user', null))
                commit('setRoleId', _.get(res, 'data.data.user.role_id', null))
                commit('setAddresses', _.get(res, 'data.data.user.addresses', null))
            }).catch(err => {
                localStorage.removeItem('api_token')
            })
        },
        authReset({commit}) {
            commit('setIsAuthenticated', false)
            commit('setToken', null)
            commit('setUser', null)
            commit('setRoleId', null)
        },
        changePassword({rootState, commit, dispatch}, payload) {
            return new Promise(async (resolve, reject) => {
                await axios.post(rootState.Constant.api_url + '/user/password', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res.data.message
                    }, {root: true})
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err.response?.data?.message ? err.response.data.message : 'Server Error!'
                    }, {root: true})
                    reject(err)
                })
            })
        },
        logout({rootState, commit, dispatch}) {
            return new Promise(async (resolve, reject) => {
                await axios.post(rootState.Constant.api_url + '/auth/logout', {}, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('api_token')
                    }
                }).then((res) => {
                    // console.log("logout success")
                    dispatch('PusherEvents/userStatusChannelUnsubscribe', null, {root: true})
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res.data.message
                    }, {root: true})
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: err.response?.data?.message ? err.response.data.message : 'Server Error!'
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
