import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            submitting: false
        }
    },
    mutations: {
        setSubmitting(state, payload) {
            state.submitting = payload
        },
    },
    actions: {
        submit({state, commit, dispatch, rootGetters}, form) {
            if (state.submitting) return
            return new Promise(async (resolve, reject) => {
                commit('setSubmitting', true)
                await axios.post(rootGetters["Constant/getApiUrl"]('contact-us'), form)
                    .then(res => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "success",
                            text: _.get(res, 'data.message', 'Successfully sent your request!')
                        }, {root: true})
                        resolve(res)
                    })
                    .catch(err => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "error",
                            text: rootGetters["FormUtils/getResponseError"](err)
                        }, {root: true})
                        reject(err)
                    })
                commit('setSubmitting', false)
            })
        },
    }
}
