import axios from "axios";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            is_deleting: false
        }
    },
    mutations: {
        setIsDeleting(state, payload) {
            state.is_deleting = payload
        }
    },
    getters: {
        commentBtnText() {
            return function (post) {
                return post.count_comments > 0 ? `Comments (${post.count_comments})` : 'Comment'
            }
        }
    },
    actions: {
        submit({dispatch, commit, rootState}, {post, comment}) {
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('FormUtils/setAllFormErrors', {
                    comment: ''
                }, {root: true})
                axios.post(rootState.Constant.api_url + '/comments', {
                    post_id: post.id,
                    comment
                }).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Comment posted successfully."
                    }, {root: true})
                    dispatch('Post/postUpdate', res.data, {root: true})
                    resolve(res)
                }).catch((err) => {
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    if (rootState.FormUtils.errMsg)
                        dispatch('Snackbar/showSnackbar', {
                            type: "error",
                            text: rootState.FormUtils.errMsg
                        }, {root: true})
                    else
                        for (let key in rootState.FormUtils.formErrors) {
                            if (rootState.FormUtils.formErrors[key])
                                dispatch('Snackbar/showSnackbar', {
                                    type: "error",
                                    text: rootState.FormUtils.formErrors[key]
                                }, {root: true})
                        }
                    reject(err)
                })
            })
        },
        deleteComment({state, dispatch, commit, rootGetters, rootState}, {comment_id, post_id}) {
            if (state.is_deleting) return false
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('setIsDeleting', true)
                axios.delete(rootGetters["Constant/getApiUrl"](`comments/${comment_id}`)).then((res) => {
                    commit('setIsDeleting', false)
                    dispatch("Post/singlePost", post_id, {root: true})
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Comment deleted successfully."
                    }, {root: true})
                    resolve(res)
                }).catch((err) => {
                    commit('setIsDeleting', false)
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: rootState.FormUtils.errMsg
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
