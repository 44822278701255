export default {
    namespaced: true,
    state() {
        return {
            page_title: ""
        }
    },
    mutations: {
        setPageTitle(state, payload) {
            state.page_title = payload
        }
    }
}
