import {createStore} from 'vuex'
import FormUtils from './FormUtils'
import Constant from './Constant'
import Snackbar from './Snackbar'
import Auth from './Auth'
import Post from './Post'
import PostComment from './PostComment'
import Product from './Product'
import FriendList from './FriendList'
import Chat from './Chat'
import Dating from './Dating'
import PusherEvents from './PusherEvents'
import Recipe from './Recipe'
import Equipment from './Equipment'
import ChatRoom from './ChatRoom'
import MapData from './MapData'
import ForgotPassword from "./ForgotPassword";
import UserProfile from "./UserProfile";
import ContactUs from "./ContactUs";
import HowToVideo from "./HowToVideo";
import HowToVideoComment from "./HowToVideoComment";
import StateCity from "./StateCity";
import Settings from "./Settings";
import Categories from "./Categories";
import Products from "./Products";
import ReportModal from "./ReportModal";
import EmailSubscription from "./EmailSubscription";
import PageSearch from "./PageSearch";
import ProductReview from "./ProductReview";
import Order from "./Order";
import OrderProducts from "./OrderProducts";
import PeopleList from "./PeopleList";
import UserDashboardUtil from "./UserDashboardUtil";
import BigChat from "./BigChat";
import Notification from "./Notification";

export default createStore({
    modules: {
        PusherEvents,
        FormUtils,
        Constant,
        Snackbar,
        Auth,
        Post,
        PostComment,
        Product,
        FriendList,
        Chat,
        Dating,
        Recipe,
        Equipment,
        ChatRoom,
        MapData,
        ForgotPassword,
        UserProfile,
        ContactUs,
        HowToVideo,
        HowToVideoComment,
        StateCity,
        Settings,
        Categories,
        Products,
        ReportModal,
        EmailSubscription,
        PageSearch,
        ProductReview,
        Order,
        OrderProducts,
        PeopleList,
        UserDashboardUtil,
        BigChat,
        Notification
    }
})
