import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            posts: [],
            selected_post: null
        }
    },
    mutations: {
        setPosts(state, payload) {
            state.posts = payload
        },
        postSelect(state, post) {
            state.selected_post = post
        },
        setPaginate(state, payload) {
            state.paginate = {
                first_page_url: payload?.first_page_url ?? null,
                last_page_url: payload?.last_page_url ?? null,
                prev_page_url: payload?.prev_page_url ?? null,
                next_page_url: payload?.next_page_url ?? null,
                current_page: payload?.current_page ?? null,

                from: payload?.from ?? null,
                to: payload?.to ?? null,
                last_page: payload?.last_page ?? null,

                per_page: payload?.per_page ?? null,
                total: payload?.total ?? null,

                links: payload?.links ?? []
            }
        }
    },
    getters: {
        userLikesName(state, getters, rootState) {
            return function (post) {
                const total = _.get(post, 'likers.total', 0)
                const likers = _.get(post, 'likers.data', [])
                const names = _.chain(likers).map((o) => {
                    return rootState.Auth.user.id === o.id ? 'You' : o.name
                }).value()
                return names.join(', ') + (total > 2 ? ' and ' + (total - 2) : '');
            }
        }
    },
    actions: {
        // async loadPosts({rootState, commit}) {
        //     let list = await axios.get(rootState.Constant.api_url + '/posts?orderBy=created_at&sortedBy=desc')
        //     list = _.get(list, 'data.data', []).map((item) => {
        //         item['created_at_now'] = moment(item['created_at']).fromNow()
        //         return item
        //     })
        //     commit('setPosts', list)
        // },
        async loadPosts({rootState, commit}, next_url = null) {
            const url = next_url !== null ? next_url : rootState.Constant.api_url + '/posts?orderBy=created_at&sortedBy=desc'
            let response = await axios.get(url)
            let list = _.get(response, 'data.data.data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })

            commit('setPosts', list)
            commit('setPaginate', response?.data?.data)
        },
        async singlePost({rootState, dispatch}, post_id) {
            let post = await axios.get( rootState.Constant.api_url + '/posts/single/' + post_id )

            dispatch('postUpdate', post.data)
        },
        togglePostLike({commit, rootState}, post_id) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/posts/' + post_id + '/like').then((res) => {
                    resolve(_.get(res, 'data', {}))
                }).catch((err) => {
                    reject(err)
                })
            })
        },
        postUpdate({state, commit}, post) {
            const index = _.findIndex(state.posts, {id: _.get(post, 'data.id')})

            if (index > -1) {
                const orgPost = state.posts[index];
                const updPost = {...orgPost, ..._.get(post, 'data')}
                let posts = state.posts
                posts.splice(index, 1, updPost)
                commit('setPosts', posts)
            }
        },
        removePost({dispatch, commit, state, rootState}) {
            return new Promise((resolve, reject) => {
                axios.delete(rootState.Constant.api_url + '/posts/' + state.selected_post.id).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Post deleted successfully."
                    }, {root: true})
                    commit('postSelect', null)
                    dispatch('Post/loadPosts', '', {root: true})
                    resolve(_.get(res, 'data', {}))
                }).catch((err) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: "Error while post deleting."
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
