import axios from "axios";
import _ from "lodash";
import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            categories: [],
        }
    },
    mutations: {
        setCategories(state, payload) {
            state.categories = payload
        },
    },
    getters: {
        getCategories(state) {
            return state.categories
        }
    },
    actions: {
        async loadCategories({rootState, commit}, query = null) {
            let list = await axios.post(rootState.Constant.api_url + '/categories', query)
            list = _.get(list, 'data', []).map((item) => {
                item['created_at_now'] = moment(item['created_at']).fromNow()
                return item
            })
            commit('setCategories', list);
        },
    }
}
