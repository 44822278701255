import moment from "moment";

export default {
    namespaced: true,
    state() {
        return {
            api_url: '',
            base_url: '',
        }
    },
    getters: {
        getAssetUrl: (state) => {
            return (url) => {
                return state.base_url + '/' + url
            }
        },
        getApiUrl: (state) => {
            return (url) => {
                return state.api_url + '/' + url
            }
        },
        createAtDate() {
            return function (date) {
                if (moment(date).isAfter(moment()) || moment(date).isSameOrAfter(moment())) {
                    return 'moments ago';
                }

                return moment(date).fromNow();
            }
        },
        thousandToK() {
            return (count) => {
                if (count >= 1000) {
                    return Math.round(count / 1000).toFixed(1) + "K";
                } else {
                    return count;
                }
            }
        }
    },
    mutations: {
        setApiUrl(state, payload) {
            state.api_url = payload
        },
        setBaseUrl(state, payload) {
            state.base_url = payload
        }
    }
}
