import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            is_deleting: false
        }
    },
    mutations: {
        setIsDeleting(state, payload) {
            state.is_deleting = payload
        }
    },
    actions: {
        async loadData({state, commit, rootGetters}, url) {
            return new Promise((resolve, reject) => {
                axios.get(url).then(res => resolve(res)).catch(err => reject(err))
            })
        },
        submit({dispatch, commit, rootGetters, rootState}, {video_id, comment}) {
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                commit('FormUtils/setAllFormErrors', {
                    video_id: '',
                    comment: ''
                }, {root: true})
                axios.post(rootGetters["Constant/getApiUrl"](`how-to-videos/${video_id}/comments`), {
                    comment
                }).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Comment posted successfully."
                    }, {root: true})
                    dispatch('Post/postUpdate', res.data, {root: true})
                    resolve(res)
                }).catch((err) => {
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    if (rootState.FormUtils.errMsg)
                        dispatch('Snackbar/showSnackbar', {
                            type: "error",
                            text: rootState.FormUtils.errMsg
                        }, {root: true})
                    else
                        for (let key in rootState.FormUtils.formErrors) {
                            if (rootState.FormUtils.formErrors[key])
                                dispatch('Snackbar/showSnackbar', {
                                    type: "error",
                                    text: rootState.FormUtils.formErrors[key]
                                }, {root: true})
                        }
                    reject(err)
                })
            })
        },
        deleteComment({state, dispatch, commit, rootGetters, rootState}, id) {
            return new Promise((resolve, reject) => {
                dispatch('Snackbar/clearSnackbar', '', {root: true})
                axios.delete(rootGetters["Constant/getApiUrl"](`comments/${id}`)).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Comment deleted successfully."
                    }, {root: true})
                    resolve(res)
                }).catch((err) => {
                    dispatch('FormUtils/showResponseErrors', err.response.data, {root: true})
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: rootState.FormUtils.errMsg
                    }, {root: true})
                    reject(err)
                })
            })
        }
    }
}
