import axios from "axios";

export default {
    namespaced: true,
    actions: {
        sendEmail({rootState, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                axios.post(rootState.Constant.api_url + '/subscribe', payload).then((res) => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: res?.data?.message
                    }, {root: true})
                    resolve(true)
                }).catch((err) => {
                    dispatch('FormUtils/showResponseErrorInSBar', err?.response, {root: true})
                    reject(err?.response?.data)
                })
            })
        },
    },
}
