import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            city_loading: false,
            states_loaded: false,
            states: [],
            cities: [],
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setCityLoading(state, payload) {
            state.city_loading = payload
        },
        setStatesLoaded(state, payload) {
            state.states_loaded = payload
        },
        setStates(state, payload) {
            state.states = payload
        },
        setCities(state, payload) {
            state.cities = payload
        }
    },
    actions: {
        async loadStates({state, commit, rootGetters}) {
            if (state.loading) return
            if (!state.states_loaded) {
                commit('setLoading', true)
                const {data} = await axios.get(rootGetters["Constant/getApiUrl"](`states`))
                commit('setStates', _.concat({id: "", name: "Select State"}, _.get(data, 'data', [])))
                commit('setStatesLoaded', true)
                commit('setLoading', false)
            }
        },
        async loadCities({state, commit, rootGetters}, state_id) {
            if (state.city_loading) return
            commit('setCityLoading', true)
            const {data} = await axios.get(rootGetters["Constant/getApiUrl"](`cities/${state_id}`))
            commit('setCities', _.concat({id: "", name: "Select City"}, _.get(data, 'data', [])))
            commit('setCityLoading', false)
        },
    }
}