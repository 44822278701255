export default {
    namespaced: true,
    state() {
        return {
            snackbar: null
        }
    },
    mutations: {
        setSnackbar(state, payload) {
            state.snackbar = payload
        }
    },
    actions: {
        showSnackbar({state}, payload) {
            /*
            * type: success, error, warning, info
            * text: {message}
            * link: https://www.npmjs.com/package/vue3-snackbar
            * */
            state.snackbar.add(payload)
        },
        clearSnackbar({state}) {
            state.snackbar.clear()
        }
    }
}
