export default {
    namespaced: true,
    state() {
        return {
            search: ''
        }
    },
    mutations: {
        setSearch(state, payload) {
            state.search = payload
        },
    },
    actions: {

    }
}
