import _ from "lodash";

export default {
  methods: {
    isMimeTypeVideo(type) {
      return _.findIndex(['video/avi', 'video/mpeg', 'video/quicktime', 'video/mp4'], function (v) {
        return v === type
      }) > -1
    }
  }
}
