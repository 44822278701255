import _ from "lodash";
import axios from "axios";

export default {
    namespaced: true,
    state() {
        return {
            list: [],
            loading: false,
            next_url: null,
            submitting: false,
            deleting: false,
            formErrors: {}
        }
    },
    mutations: {
        setList(state, value) {
            state.list = value
        },
        setLoading(state, value) {
            state.loading = value
        },
        setNextUrl(state, value) {
            state.next_url = value
        },
        setSubmitting(state, value) {
            state.submitting = value
        },
        setDeleting(state, value) {
            state.deleting = value
        },
        setFormErrors(state, value) {
            state.formErrors = value
        },
    },
    actions: {
        async loadData({state, commit, rootGetters}, payload) {
            const next_url = _.get(payload, 'next_url', null)
            if (state.loading) return;
            commit('setLoading', true)
            const url = next_url ? next_url : rootGetters["Constant/getApiUrl"]('how-to-videos')
            const {data} = await axios.get(url)
            commit('setNextUrl', _.get(data, 'data.next_page_url', null))
            let list = _.get(data, 'data.data', [])
            if (next_url)
                list = _.concat(state.list, list)
            commit('setList', list)
            commit('setLoading', false)
        },
        uploadVideo({state, commit, dispatch, rootGetters}, {formData}) {
            if (state.submitting) return
            return new Promise(async (resolve, reject) => {
                commit('setSubmitting', true)
                commit('setFormErrors', {})
                await axios.post(rootGetters["Constant/getApiUrl"]('how-to-videos'), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Upload video successfully."
                    }, {root: true})
                    dispatch('loadData')
                    resolve()
                }).catch(err => {
                    commit('setFormErrors', _.get(err.response.data, 'errors', {}))
                    reject()
                }).finally(() => {
                    commit('setSubmitting', false)
                })
            })
        },
        updateVideo({state, commit, dispatch, rootGetters}, {id, formData}) {
            if (state.submitting) return
            return new Promise(async (resolve, reject) => {
                commit('setSubmitting', true)
                commit('setFormErrors', {})
                formData.append('_method', 'PUT')
                await axios.post(rootGetters["Constant/getApiUrl"](`how-to-videos/${id}`), formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Update video successfully."
                    }, {root: true})
                    dispatch('loadData')
                    resolve()
                }).catch(err => {
                    commit('setFormErrors', _.get(err.response.data, 'errors', {}))
                    reject()
                }).finally(() => {
                    commit('setSubmitting', false)
                })
            })
        },
        deleteVideo({state, commit, dispatch, rootGetters}, id) {
            if (state.deleting) return
            return new Promise(async (resolve, reject) => {
                commit('setDeleting', true)
                await axios.delete(rootGetters["Constant/getApiUrl"](`how-to-videos/${id}`)).then(res => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "success",
                        text: "Video deleted successfully."
                    }, {root: true})
                    dispatch('loadData')
                    resolve()
                }).catch(err => {
                    dispatch('Snackbar/showSnackbar', {
                        type: "error",
                        text: "Error while deleting video!"
                    }, {root: true})
                    reject()
                }).finally(() => {
                    commit('setDeleting', false)
                })
            })
        }
    }
}
