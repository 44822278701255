import axios from "axios";
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            loadingPost: false,
            posts: [],
            postCount: 0,
            connectionCount: 0,
            user_profile_loading: false,
            user_profile: null,
        }
    },
    mutations: {
        setLoadingPost(state, payload) {
            state.loadingPost = payload
        },
        setPosts(state, payload) {
            state.posts = payload
        },
        setPostCount(state, payload) {
            state.postCount = payload
        },
        setConnectionCount(state, payload) {
            state.connectionCount = payload
        },
        setUserProfileLoading(state, value) {
            state.user_profile_loading = value
        },
        setUserProfile(state, value) {
            state.user_profile = value
        }
    },
    actions: {
        async loadPosts({state, rootGetters, commit}, user_id = null) {
            if (state.loadingPost) return;
            commit('setLoadingPost', true)
            let url = rootGetters["Constant/getApiUrl"]('profile/posts')
            if (user_id) url += `/${user_id}`
            let {data} = await axios.get(url)
            commit('setPosts', _.get(data, 'data.data'))
            commit('setLoadingPost', false)
        },
        async loadPostCount({rootGetters, commit}, user_id = null) {
            let url = rootGetters["Constant/getApiUrl"]('profile/post-count')
            if (user_id) url += `/${user_id}`
            let {data} = await axios.get(url)
            commit('setPostCount', _.get(data, 'data.count'), 0)
        },
        async loadConnectionCount({rootGetters, commit}, user_id = null) {
            let url = rootGetters["Constant/getApiUrl"]('profile/connection-count')
            if (user_id) url += `/${user_id}`
            let {data} = await axios.get(url)
            commit('setConnectionCount', _.get(data, 'data.count'), 0)
        },
        loadUserProfile({state, commit, rootGetters}, id) {
            if (state.user_profile_loading) return;
            return new Promise(async (resolve, reject) => {
                commit('setUserProfileLoading', true)
                await axios.get(rootGetters["Constant/getApiUrl"](`profile/user/${id}`)).then(res => {
                    commit('setUserProfile', _.get(res, 'data.data'))
                    resolve(res)
                }).catch(err => {
                    reject(err)
                }).finally(() => {
                    commit('setUserProfileLoading', false)
                })
            })
        },
        updateProfile({state, commit, rootGetters}, formData) {
            return new Promise((resolve, reject) => {
                axios.post(rootGetters["Constant/getApiUrl"](`user/edit`), formData).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        searchInterests({commit, rootGetters}, payload) {
            return new Promise(async (resolve, reject) => {
                await axios.get(rootGetters["Constant/getApiUrl"](`profile/interests/${payload}`)).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        loadInterests({rootGetters}) {
            return new Promise(async (resolve, reject) => {
                await axios.get(rootGetters["Constant/getApiUrl"](`profile/interests`)).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}
