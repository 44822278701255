import axios from 'axios'
import _ from "lodash";

export default {
    namespaced: true,
    state() {
        return {
            loading: false,
            check_token_loading: false
        }
    },
    mutations: {
        setLoading(state, payload) {
            state.loading = payload
        },
        setCheckTokenLoading(state, payload) {
            state.check_token_loading = payload
        }
    },
    actions: {
        submit({state, commit, dispatch, rootGetters}, email) {
            if (state.loading) return
            return new Promise(async (resolve, reject) => {
                commit('setLoading', true)
                await axios.post(rootGetters["Constant/getApiUrl"]('auth/forgot-password'), {email})
                    .then(res => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "success",
                            text: _.get(res, 'data.message', 'Successfully send email!')
                        }, {root: true})
                        resolve(res)
                    })
                    .catch(err => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "error",
                            text: rootGetters["FormUtils/getResponseError"](err, 'email[0]')
                        }, {root: true})
                        reject(err)
                    })
                commit('setLoading', false)
            })
        },
        checkToken({commit, state, rootGetters}, token) {
            if (state.check_token_loading) return
            return new Promise(async (resolve, reject) => {
                commit('setCheckTokenLoading', true)
                await axios.get(rootGetters["Constant/getApiUrl"](`auth/check-reset-password-token/${token}`)).then(res => resolve(res)).catch(err => reject(err))
                commit('setCheckTokenLoading', false)
            })
        },
        submitResetPassword({state, commit, dispatch, rootGetters}, payload) {
            if (state.loading) return
            return new Promise(async (resolve, reject) => {
                commit('setLoading', true)
                await axios.post(rootGetters["Constant/getApiUrl"]('auth/reset-password'), payload)
                    .then(res => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "success",
                            text: _.get(res, 'data.message', 'Successfully changed password!')
                        }, {root: true})
                        resolve(res)
                    })
                    .catch(err => {
                        dispatch('Snackbar/showSnackbar', {
                            type: "error",
                            text: rootGetters["FormUtils/getDefault"](err, 'response.data.message', 'Server Error!')
                        }, {root: true})
                        reject(err)
                    })
                commit('setLoading', false)
            })
        }
    }

}
