import axios from "axios";
import _ from "lodash";
import {commit} from "lodash/seq";

export default {
  namespaced: true,
  state() {
    return {
      user_id: null,
      chat_messages_panel_active: false,
      chat_message_loading: false,
      is_load_more: false,
      next_page_url: null,
      chat_channel_id: null,
      messages: []
    }
  },
  getters: {
    isUserIdOrChannelId(state) {
      return state.user_id !== null || state.chat_channel_id !== null
    },
    getMessages(state) {
      return _.orderBy(state.messages, 'created_at', 'asc')
    }
  },
  mutations: {
    setUserId(state, payload) {
      state.user_id = payload
    },
    setChatMessagesPanelActive(state, payload) {
      state.chat_messages_panel_active = payload
    },
    setChatMessageLoading(state, payload) {
      state.chat_message_loading = payload
    },
    setChannelId(state, payload) {
      state.chat_channel_id = payload
    },
    setMessages(state, payload) {
      state.messages = payload
    },
    addMessage(state, payload) {
      setTimeout(() => {
        if (!_.find(state.messages, {id: payload.id}))
          state.messages = _.concat(state.messages, payload)
      }, 100)
    },
    setNextPageUrl(state, payload) {
      state.next_page_url = payload
    },
    setIsLoadMore(state, payload) {
      state.is_load_more = payload
    }
  },
  actions: {
    async directUserChat({commit, dispatch, rootState}, payload) {
      dispatch('resetMessagesAndCommits')
      commit('setUserId', payload)
      commit('setChatMessagesPanelActive', true)
      commit('setChatMessageLoading', true)
      let {data} = await axios.get(`${rootState.Constant.api_url}/chats/messages/${payload}`)
      commit('setNextPageUrl', _.get(data, 'data.messages.next_page_url', null))
      commit('setChannelId', _.get(data, 'data.channel_id', null))
      commit('setMessages', _.get(data, 'data.messages.data', []))
      commit('FriendList/removeNotifyItem', payload, {root: true})
      commit('setChatMessageLoading', false)
    },
    async directGroupChat({commit, dispatch, rootState}, payload) {
      dispatch('resetMessagesAndCommits')
      commit('setChatMessagesPanelActive', true)
      commit('setChatMessageLoading', true)
      commit('setChannelId', payload)
      let {data} = await axios.get(`${rootState.Constant.api_url}/chats/messages/group/${payload}`)
      commit('setNextPageUrl', _.get(data, 'data.messages.next_page_url', null))
      commit('setMessages', _.get(data, 'data.messages.data', []))
      commit('setChatMessageLoading', false)
    },
    async loadMore({state, commit}) {
      if (state.chat_message_loading) return
      commit('setChatMessageLoading', true)
      commit('setIsLoadMore', true)
      let {data} = await axios.get(`${state.next_page_url}`)
      commit('setMessages', _.concat(state.messages, data.data.messages.data))
      commit('setNextPageUrl', _.get(data, 'data.messages.next_page_url', null))
      commit('setChatMessageLoading', false)
    },
    async sendMessage({commit, state, dispatch, rootGetters, rootState}, form_data) {
      commit('setIsLoadMore', false)
      await axios.post(`${rootState.Constant.api_url}/chats/messages/${state.chat_channel_id}`, form_data)
        .then(res => {
          let messagesList = state.messages
          let messageRow = _.get(res, 'data.data', null)
          if (messageRow) {
            messagesList.push(messageRow)
            commit('setMessages', messagesList)
          }
        }).catch(err => {
          dispatch('Snackbar/showSnackbar', {
            type: "error",
            text: rootGetters["FormUtils/getResponseError"](err)
          }, {root: true})
        })
    },
    resetMessagesAndCommits({commit}) {
      commit('setIsLoadMore', false)
      commit('setChannelId', null)
      commit('setUserId', null)
      commit('setNextPageUrl', null)
      commit('setMessages', [])
    },
  }
}
