<template>
    <div>
        <router-view></router-view>
        <teleport to="body">
            <vue3-snackbar bottom right :duration="4000"></vue3-snackbar>
        </teleport>
        <notifications />
    </div>
</template>

<script>
import GlobalMixin from './mixins/global'
import {useStore} from 'vuex'
import {computed, onMounted, onUnmounted, watchEffect} from "vue";
import {useRouter} from "vue-router";

export default {
    name: "app",
    props: ['baseUrl', 'apiUrl'],
    mixins: [GlobalMixin],
    setup(props) {
        const store = useStore()
        const router = useRouter()
        const is_auth = computed(() => store.state.Auth.is_authenticated)
        const userStatusChange = (store) => {
            // user online status change
            if (store.state.Auth.is_authenticated) {
                store.dispatch('PusherEvents/userStatusOnline')
            }
        }

        watchEffect(() => {
            if (is_auth.value) {
                userStatusChange(store)
            }
        })

        store.commit('Constant/setApiUrl', props.apiUrl)
        store.commit('Constant/setBaseUrl', props.baseUrl)

        onMounted(() => {
            userStatusChange(store)
        })

        onUnmounted(() => {
            console.log("app onUnmounted")
        })

        return {
            getAssetUrl: computed(() => store.getters['Constant/getAssetUrl']),
            goToUserProfile: (id) => {
                if (store.state.Auth.is_authenticated && id === store.state.Auth.user.id)
                    router.push({name: 'profile'})
                else
                    router.push({name: 'user-profile', params: {id}})
            },
        }
    },
    beforeCreate() {
        this.$store.commit('Snackbar/setSnackbar', this.$snackbar)
    }
}
</script>

<style scoped>

</style>
