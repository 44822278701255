import {createApp} from "vue";
import axios from "axios";
import {SnackbarService, Vue3Snackbar} from 'vue3-snackbar';
import VueSocialSharing from 'vue-social-sharing'
import "vue3-snackbar/dist/style.css";

import App from "./App.vue";
import store from './store/index.js'
import router from "./routes";
// import {setup as EchoSetup} from "./laravel-echo"
import Notifications from '@kyvg/vue3-notification';
import mitt from 'mitt'
import VueLazyload from "vue-lazyload";

const appRoot = document.getElementById('app')
const app = createApp(App, {...appRoot.dataset})
const emitter = mitt();

app.use(SnackbarService)
app.use(store)
app.use(router)
app.use(VueSocialSharing)
app.use(Notifications)
app.use(VueLazyload, {
  preLoad: 1.3,
  // error: '/social-hunter.com/public/images/error.gif',
  // loading: '/social-hunter.com/public/images/loading.gif',
  attempt: 1
})
app.component("vue3-snackbar", Vue3Snackbar)

app.config.globalProperties.$axios = axios
app.config.globalProperties.$emitter = emitter;

// EchoSetup(app)

app.mount('#app')

